import RestService from './restService';
import config, { telemetryConstants } from '../config';
import { telemetry } from './telemetryService';
import RuntimeConfigStateContainer from '../stateContainers/runtimeConfigStateContainer';
import * as WebAppApiType from '../webAppApi/index';

const CesService = {
  getEligibility: async (sourcePage: string) => {
    var response;

    try {
      if (sourcePage === config.pages.Home) {
        telemetry.logTrace('CES survey eligibility check API - Home page', telemetryConstants.severity.SEVERITY_INFO);

        response = await RestService.get({
          endPoint: config.endpoints.cesCheckEligibility,
        });

        telemetry.logTrace(
          "CES Check Eligibility API called and returned the user's eligibility as " + response + ' - Home page',
          telemetryConstants.severity.SEVERITY_INFO
        );
      } else if (sourcePage === config.pages.Deploy) {
        telemetry.logTrace(
          'CES survey event trigger and eligibility check API - Deploy Page',
          telemetryConstants.severity.SEVERITY_INFO
        );
        const runtimeConfig: WebAppApiType.ClientConfiguration = RuntimeConfigStateContainer.getConfiguration();
        response = await RestService.post({
          endPoint:
            config.endpoints.cesTriggerEventAndCheckEligibility +
            config.endpoints.cesTriggerEvent +
            runtimeConfig.survey.eventTriggerName,
          data: null,
        });
        telemetry.logTrace(
          "CES Trigger Event and Check Eligibility API called and returned the user's eligibility as " +
            response +
            '- Deploy Page',
          telemetryConstants.severity.SEVERITY_INFO
        );
      }

      return response;
    } catch (ex) {
      telemetry.logException(ex);
      telemetry.logTrace(
        'Failure in calling CES eligibility check API on the ' + sourcePage + 'page',
        telemetryConstants.severity.SEVERITY_ERROR
      );
    }
  },
};

export default CesService;
